.cardMain{
  width: 100%;
  margin-bottom: 15px;
  transition: opacity 0.3s;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  align-items: right;
  justify-content: right;
  text-align: right;
}

.cards-list{
    flex-direction: column;
    align-items: right;
    text-align: right;
    padding: 2%;
}

.cardMain:focus {
  background-color:red;
}

.card-text {
  padding: 7px;
}

.inline {
  display: inline;
}

.forget-password a {
  color: #404040;
  text-decoration: underline;
}