.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider.round:before {
  border-radius: 50%;
}