.UploadImageTitle { 
    text-align: center;
    color: #007bff;
}

.HiddenInput {
  visibility:hidden;
  width:0;
  height:0;
}

.EmptyImageBox {
    background-color: #F5F5F5;
    color: #E8E8E8;
    border: dashed;
    border-radius: 25px;
    height: 20vh;
    line-height: 20vh;
    margin: 1% auto;
}

.EmptyImageBox label {
    text-align: center;
    font-size: 30px;
    height: 100%;
    width: 100%;
}

.UploadedImage {
    width: 100%;
    overflow: hidden;
    margin: 2% auto;
    display: inline-block;
    position: relative;
}

.UploadedImage label {
    text-align: center;
    margin: auto;
   }

.UploadedImage img {
    max-width: 100%;
    height: auto;
}

.UploadedImage embed {
    height: 80vh;
}

.DeleteIcon {
    position: absolute;
    top: 0;
    right: 0;
}

.UploadedImage img:hover {
    opacity: 0.5;
    transition: .5s ease;
}

.UploadedImage embed:hover {
    opacity: 0.5;
    transition: .5s ease;
}

