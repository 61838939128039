.LoginBoxPopUp {
    position: fixed;
    z-index: 500;
    width: 80%;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px black;
    padding: 40px 16px 16px 16px;
    left: 10%;
    top: 15%;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
    background-color: #fff;
    overflow: auto;
    height: 70%;
}

@media only screen and (min-width: 900px) {
  .LoginBoxPopUp {
    width: 40%;
    left: 30%;
  }
}

@media only screen and (max-width: 900px) {
  .LoginBoxPopUp {
    width: 70%;
    left: 15%;
  }
}

@media only screen and (max-width: 700px) {
  .LoginBoxPopUp {
    width: 80%;
    left: 10%;
  }
}

.LoginBoxPopUp-header {
    margin: 0 15px 10px 15px;
    color: #007bbf;
    font-size: 18px;
    text-align: center;
}

.LoginBoxPopUp-input-wrap {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.LoginBoxPopUp-input-wrap .TextField {
    width: 75%;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-top: none;
    border-left: none;
    border-right: none;
}

.LoginBoxPopUp-input-wrap input::placeholder {
    color: #ccc;
}

.LoginBoxPopUp-input-wrap input:focus {
    outline: none
}

.LoginBoxPopUp-button-wrap {
    margin-top: 35px;
    text-align: center;
}
