
.Brand {
    max-width: 1000px;
    position: relative;
    margin: auto;
    color: white;
}

.Brand img {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.card-body button {
  margin-top: 10px;
}

/* Next & previous buttons */
.Brand-prev, .Brand-next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -22px;
    font-weight: bold;
    font-size: 25px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    background-color: rgba(0,0,0,0.8);
  }

.Brand-next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

.Brand-prev {
  left: 0;
  border-radius: 3px 0 0 3px;
}

.Brand-prev:hover, .Brand-next:hover {
    background-color: rgba(0,0,0,0.8);
}

@media (min-width: 990px) {
  .Brand img {

    height: 295px;
    margin: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    width: 80%;

  }

}