.TicketOpen {
    color: red;
}

.TicketClose {
    color: green;
}

.TicketNormal {
    color: blue; 
}
