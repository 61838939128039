.UploadImageTitle { 
    text-align: center;
    color: #007bff;
}

.HiddenInput {
  visibility:hidden;
  width:0;
  height:0;
}

.EmptyImageBox {
    display: flex;
    background-color: #F5F5F5;
    color: #E8E8E8;
    border: dashed;
    border-radius: 25px;
    height: 20vh;
    margin: 1% auto;
}

.EmptyImageBox img {
    width: 10%;
    height: 10%;
}

.EmptyImageBox label {
    text-align: center;
    font-size: 30px;
    margin: auto;
}

.UploadedImage {
    display: flex;
    width: 100%;
    overflow: hidden;
}

.UploadedImage img {
    max-height: 100%;
    margin: auto;
}
