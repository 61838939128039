body {
  margin: 0;
  font-size: 16px;
  /* background-color: #f8f9fa!important; */
  background-color: #fff!important;
  font-family: 'Kanit', sans-serif!important;
}
.font-text{
  font-family: 'Kanit', sans-serif!important;
}
.bg-dark{
  background-color:#00349A!important
}

.jumbotron{
  background-color: #fff!important;

}
.jumbotron-heading{
  font-weight: 300!important;;
}
.btn-complaint{
  text-align: center;
  width: 100%;

}
.col-complaint{
  background-color: #fff!important;
  padding: 5%!important;
}
.card-makecomplaint{
  padding: 2%;

}
.col-step{
  background-color: #fff!important;
  padding: 5%!important;
}
.padding-five{
  padding: 5%!important;
}
.margin-bottom-five{
  margin-bottom: 5%!important;
  /* margin-top: 5%; */
}
.font-step{
  font-size: 15px;
}
a {
  color: #00bcd4;
  font-weight: bold;
  text-decoration: none;
  transition: color 0.4s;
}

a:hover {
  color: #1976d2;
}

.text-color{
  color: #007bff;
}
.btnback{
  background-color: gray;
  border-color: gray;
  color : #fff;
}
.font-all{
  font-family: 'Kanit', sans-serif!important;
}
.font-gray{
  font-family: 'Kanit', sans-serif;
  color : gray;
}
.font-red{
  color : red;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.top-bar {
  padding: 10px 15px;
  margin-bottom: 50px;
}
.top-bar::after {
  content: '';
  display: block;
  clear: both;
}

.top-bar-left {
  float: left;
  font-size: 1.5em;
}

.top-bar-right {
  float: right;
}

.top-bar a,
.nav a {
  margin: 0 8px;
}

.username-header{
  color: #f8f9fa;
  display: inline;
  padding: 15px;
}

/* .container {
  margin: 0 auto;
  text-align: center;
  width: 700px;
} */

.card-heading {
  padding: 16px;
}

.field-line, .button-line {
  padding: 16px;
}

.error-message {
  padding: 0 16px;
  color: tomato;
}

.success-message {
  padding: 0 16px;
  color: green;
}

.img-width-80{

  width: 80%;
}

.img-middle{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
.img-loader{
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
}
.margin-top-15{
  margin-top:15px;
}

.float-right{
  float: right
}

.float-left{
  float: left
}

.fixed-bar{
  overflow: hidden;
  background-color: #333;
  position: fixed;
}
.z-index{
  z-index: 99;
}
.ant-affix{
  z-index: 10;
  background-color: #fff!important;
}
/* @media screen and (max-width: 420px){
  #content-Desktop {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }
}


@media screen and (min-width: 421px){
  #step-mobile {
    visibility: hidden;
    clear: both;   
    display: none;
  }
} */

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  #step-mobile {
    visibility: hidden;
    clear: both;   
    display: none;
  }
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  #step-mobile {
    visibility: hidden;
    clear: both;   
    display: none;
  }
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  #step-mobile {
    visibility: hidden;
    clear: both;   
    display: none;
  }
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  #step-mobile {
    visibility: hidden;
    clear: both;   
    display: none;
  }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  #content-Desktop {
    visibility: hidden;
    clear: both;
    display: none;
  }

}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  #content-Desktop {
    visibility: hidden;
    clear: both;
    display: none;
  } 
}


.loader {
  position: relative;
}

.loader::after {
  background-color: rgba(0,0,0,.7);
  content: 'Loading...';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.herdercard{

  margin-top: 2%;
}

