.hero-slide {
  display: flex;
  position: relative;
  overflow: hidden;
}

.hero-slider .content {
  position: relative;
  z-index: 2;
}

.hero-slider img {
    max-width: 100%;
    height: auto;
    margin-bottom: 30px;
}
