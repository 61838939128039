.Accept {
    position: fixed;
    z-index: 500;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px black;
    padding: 16px 16px 16px 16px;
    width: 80%;
    left: 10%;
    top: 20%;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
    background-color: #fff;
    overflow: auto;
    height: 70%;
    overflow: scroll;
    font-size: 12px;
}

.Accept-checkbox {
    margin-top: 20px;
    text-align: center;
}

.Accept-checkbox label {
    margin-left: 3px;
    vertical-align: middle;
}
